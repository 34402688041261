.b-tabs {
  padding: 4px 0 16px;

  &__wrapper {
    display: flex;
    gap: 16px;

    & > a {
      text-decoration: none;
      color: var(--text-secondary);
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      position: relative;
      padding: 10px 0;
      height: 40px;

      & > span {
        line-height: 16px;
      }

      &:hover {
        color: var(--controls-neutral-controls);
      }

      &:active {
        color: var(--controls-primary-background);
      }
    }
  }

  &--active {
    color: var(--controls-primary-background) !important;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      background-color: var(--controls-primary-background);
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}
