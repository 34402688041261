.p-order-item {
  background: linear-gradient(180deg, #051626 0%, #285884 100%);
  background-attachment: fixed;
  width: 100%;
  min-height: calc(100vh - var(--header-height));
  height: 100%;
  padding: 24px;

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    & > div {
      flex: 1;
      min-width: 350px;
      height: fit-content;
    }
  }

  &__card {
    background: var(--main-white);
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &_content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }

    &_status {
      background-color: var(--royal-blue-100);
      padding: 12px 16px;
      border-radius: 16px;
    }
  }

  &__form-submit {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__status-history {
    padding: 12px 0;
    display: flex;
    flex-direction: column;
  }
}

.b-order-status-change {
  display: flex;
  gap: 12px;

  &__step {
    width: 48px;
    min-width: 48px;
    position: relative;

    & > div {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
    }
    &_closed {
      background-color: var(--badge-positive-pale);
    }
    &_canceled {
      background-color: var(--badge-negative-pale);
    }
    &_number {
      background-color: var(--badge-blue-pale);
    }

    &:not(&--last)::after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 1.5px;
      height: calc(100% - 48px);
      transform: translateX(-50%);
      z-index: 1;
      background-color: var(--main-primary);
    }
  }

  &__content {
    width: calc(100% - 60px);

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 12px 0;
      min-height: 48px;
    }

    &_adds > div {
      & > span {
        margin-right: 8px;
      }
      & > p {
        display: contents;
      }
    }
  }
}

.b-order-status-select {
  &__toggler {
    display: flex;
    align-items: center;
    height: 28px;
    gap: 2px;
    padding: 4px 4px 4px 8px;
    border-radius: 10px;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 4px 12px;

    &_item {
      background-color: var(--neutral-100);
      height: 36px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;

      &--selected {
        outline: 1px solid var(--main-primary);
      }
    }
  }
}
