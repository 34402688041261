.p-occ-initiative-details {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__card {
    padding: 20px;
    background-color: var(--background-content);
    box-shadow: 0px 6px 34px 0px rgba(0, 0, 31, 0.12);
    border-radius: var(--big-card-border-radius);
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__table {
    display: table;

    & > div {
      display: table-row;
      gap: 24px;

      &:not(:first-child) > span {
        padding-top: 12px;
      }

      & > span {
        display: table-cell;

        &:first-child {
          padding-right: 16px;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & > div {
      display: flex;
      gap: 16px;
    }

    &_decline {
      width: 118px;
      & div {
        display: flex;
        width: 100%;
      }
      & button {
        width: 100%;
      }
    }
    &_accept {
      width: calc(100% - 118px - 16px);

      & div {
        display: flex;
        width: 100%;
      }
      & button {
        width: 100%;
      }
    }
  }

  &__stats {
    display: flex;
    gap: 16px;

    &_item {
      flex: 1;
      background-color: var(--background-content-tint);
      border-radius: 24px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &_percentage {
      padding: 4px 8px;
      border-radius: 8px;
    }
  }

  &__shine-btn {
    position: relative;
    overflow: hidden;

    &::before {
      position: absolute;
      top: 0;
      left: -500px;
      content: "";
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.85) 19.79%,
        #ffffff 26.04%,
        rgba(255, 255, 255, 0.85) 32.29%,
        rgba(255, 255, 255, 0) 50%
      );
      filter: blur(50px);
      transform: skew(-15deg);
      animation: shine 2s ease infinite;
    }
  }
}

@keyframes shine {
  from {
    left: -500px;
  }
  to {
    left: 655px;
  }
}
