.table_container {
  width: 100%;
  transform: rotateX(180deg);
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;

  & > * {
    transform: rotateX(180deg);
  }
}

.b-occ-initiative-question-collapse {
  cursor: pointer;
  color: var(--text-link);
  font-style: italic;
}

.b-occ-nitiative-question__row {
  vertical-align: baseline;
  & > :first-child {
    width: 130px;
  }
  & > :nth-child(2) {
    width: 350px;
  }
}
