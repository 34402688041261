.p-statistics {
  padding-bottom: 24px;

  &__subtitle {
    padding: 24px 0 12px;

    &_gradient {
      background-image: linear-gradient(90deg, #2e64f7 0%, #d8e6ff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent !important;
    }
  }

  &__group {
    &_header {
      padding: 20px 0 12px;
    }
    &_content {
      display: grid;
      grid-column-gap: 16px;
      grid-row-gap: 16px;
    }
  }

  &__card {
    border-radius: 32px;

    &--imaged {
      background-size: cover;
      background-position: center;
      position: relative;
      overflow: hidden;

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
      }
    }

    & > div {
      padding: 24px;
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      height: 100%;

      & > p {
        font-size: 40px;

        & > span {
          line-height: 1;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent !important;
          word-break: break-word;
        }
      }
    }
  }

  // users
  &__users &__group_content {
    grid-template-columns: 330px repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  &__users {
    &_count {
      grid-area: 1 / 1 / 3 / 2;
      background: linear-gradient(105.2deg, #6aa517 0%, #0512a3 100%);
      text-align: center;
      height: 330px;

      & > div > p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 100%;
        font-size: 64px;

        & > span {
          background-color: var(--lkl-c--text-white);
        }
      }
    }
    &_mkd-count {
      grid-area: 1 / 2 / 2 / 4;
    }
    &_snt-count {
      grid-area: 2 / 2 / 3 / 3;
    }
    &_private-house-count {
      grid-area: 2 / 3 / 3 / 4;
    }

    &_mkd-count > div > p > span,
    &_snt-count > div > p > span,
    &_private-house-count > div > p > span {
      background-image: linear-gradient(90deg, #2e64f7 0%, #d8e6ff 100%);
    }
  }

  // orgs
  &__orgs &__group_content {
    display: grid;
    grid-template-columns: repeat(2, 1fr) 200px;
    grid-template-rows: 1fr;
    grid-column-gap: 16px;
  }

  &__orgs &__card {
    background-color: var(--lkl-c--background-page);
  }

  &__orgs {
    &_uk {
      grid-area: 1 / 1 / 2 / 2;
      & > div > p > span {
        background-image: linear-gradient(90deg, #1387fa 0%, #004c92 100%);
      }
    }
    &_snt {
      grid-area: 1 / 2 / 2 / 3;

      & > div > p > span {
        background-image: linear-gradient(270deg, #006a14 0%, #0ea643 100%);
      }
    }
    &_business {
      grid-area: 1 / 3 / 2 / 4;

      & > div > p > span {
        background-image: linear-gradient(90deg, #62656a 0%, #151616 100%);
      }
    }
  }

  &__occ-card {
    border-radius: 32px;
    background: linear-gradient(90deg, #263445 0%, #894b1c 100%);
    display: flex;
    justify-content: space-between;

    & > div {
      min-height: 152px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      padding: 24px;

      & > p {
        font-size: 40px;
        color: rgba(255, 255, 255, 0.8);
        line-height: 1;
      }
    }

    &_quorum {
      border-radius: 28px;
      background: rgba(0, 0, 0, 0.5);
      margin: 12px;
      backdrop-filter: blur(8px);
    }
  }
}
