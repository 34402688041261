.table_container {
  width: 100%;
  transform: rotateX(180deg);
  overflow-x: auto;
  width: 100%;

  & > * {
    transform: rotateX(180deg);
  }
}

.b-occ-nitiative-votes__row {
  vertical-align: baseline;
}
