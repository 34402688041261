@import "../../assets/styles/colors.scss";
@import "../../assets/styles/common.scss";

.btn {
  @include sf-text-medium;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 10px 12px;
  position: relative;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.size-normal {
    height: var(--base-field-height);
    font-size: 14px;
    letter-spacing: -0.154px;
    border-radius: 12px;
  }
  &.size-medium {
    height: 36px;
    font-size: 13px;
    letter-spacing: -0.078px;
    border-radius: 10px;
  }
  &.size-small {
    height: 28px;
    font-size: 12px;
    border-radius: 8px;
  }

  &.variant-primary {
    &.appearance-default {
      background-color: $secondaryNew;
      color: $primary;

      &:hover {
        background-color: $secondarySelection;
      }

      &:active {
        background-color: #1252cd;
      }
    }

    &.appearance-positive {
      background-color: #0bb261;
      color: $white;

      &:hover {
        background-color: #0ba65b;
      }

      &:active {
        background-color: #099551;
      }
    }

    &.appearance-negative {
      background-color: #ed0a34;
      color: $white;

      &:hover {
        background-color: #e40a34;
      }

      &:active {
        background-color: #d70830;
      }
    }

    &.appearance-accent {
      background-color: var(--controls-primary-background);
      color: var(--controls-primary-foreground);

      &:hover {
        background-color: var(--controls-primary-controls-hover);
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.variant-secondary {
    background: #e9edf3;

    &:hover {
      background-color: #e0e5ef;
    }

    &:active {
      background-color: #d6dce8;
    }

    &.appearance-default {
      color: $secondaryNew;
    }

    &.appearance-accent {
      color: var(--controls-primary-background);
    }

    &.appearance-positive {
      color: #0bb261;
    }

    &.appearance-negative {
      color: #ed0a34;
    }

    &.appearance-neutral {
      color: #2e2d2d;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.variant-tertiary {
    &:hover {
      background-color: #e0e5ef;
    }

    &:active {
      background-color: #d6dce8;
    }

    &.appearance-default {
      color: $secondaryNew;
    }

    &.appearance-positive {
      color: #0bb261;
    }

    &.appearance-negative {
      color: #ed0a34;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.variant-icon-round {
    background-color: $primary;
    border-radius: 50%;
    height: 36px;
    width: 36px;
  }

  &.variant-icon-round:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &.variant-icon-squared {
    background-color: #fff;
    padding: 12px;
    height: auto;

    &:hover {
      background-color: black;

      & svg path {
        fill: #fff;
      }
    }
  }

  &.variant-icon-basic {
    padding: 4px 8px;
    height: auto;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &.loading .content {
    opacity: 0;
  }

  & .content {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  & .start_icon {
    display: inherit;
    margin-right: 4px;
  }
  & .end_icon {
    display: inherit;
    margin-left: 4px;
  }
}

.loader {
  position: absolute;
  visibility: visible;
  display: flex;
  left: 50%;
  transform: translate(-50%);
}
