.p-occ-initiatives {
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  &__row {
    vertical-align: baseline;
  }
}
