.empty {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 495px;
  }
}
