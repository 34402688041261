@import "../../assets/styles/colors.scss";
@import "../../assets/styles/common.scss";

.root {
  width: 100%;
  position: relative;

  &.disabled:not(.variant-formfield) .display,
  &.disabled.transparent-on-disable {
    opacity: 0.5;
    pointer-events: none;
  }

  &::after {
    content: attr(data-error);
    display: block;
    color: $red;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.154px;
    max-height: 0;
    margin-top: 0;
    transition: all 0.2s ease;
    overflow: hidden;
  }

  &.has_error::after {
    margin-top: 4px;
    max-height: 54px;
  }

  &.variant-formfield .display {
    height: 40px;
    padding: 0 12px;
    border-radius: 10px;
    border: 1px solid var(--background-border);
    background-color: white;

    & > div {
      height: 100%;
      justify-content: space-between;

      & > span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    & .placeholder {
      color: #818c99;
    }
  }

  &.variant-formfield.has_error .display {
    border-color: $red;
  }
}

.display {
  @include sf-text-regular;

  width: 100%;
  background: none;
  box-shadow: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.154px;
  cursor: pointer;
  user-select: none;

  & > div {
    display: flex;
    align-items: center;
    gap: 2px;

    & .icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &.display_opened {
        transform: rotate(-180deg);
      }
    }
  }

  .placeholder {
    color: $text-primary;
  }
}

.body {
  border-radius: 12px;
  background: $primary;
  padding: 8px 0px;
  box-shadow: 0px 3px 8px 0px rgba(0, 17, 51, 0.08), 0px 18px 28px 0px rgba(0, 17, 51, 0.16);
  position: absolute;
  z-index: 9999;
  max-height: 400px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    position: relative;
  }
  &::-webkit-scrollbar-track {
    width: 14px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 14px;
    background: #d6d9de;
    border: 3px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
  }
}

.menu_item {
  list-style: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  gap: 8px;
  padding: 8px 12px;
  min-height: 36px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.154px;

  &.default_hover:hover {
    background-color: $neutral100;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.selectable {
  & .menu_item {
    padding: 8px 36px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.selected::after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.7071 3.29289C15.0976 3.68342 15.0976 4.31658 14.7071 4.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L1.29289 8.70711C0.902369 8.31658 0.902369 7.68342 1.29289 7.29289C1.68342 6.90237 2.31658 6.90237 2.70711 7.29289L6 10.5858L13.2929 3.29289C13.6834 2.90237 14.3166 2.90237 14.7071 3.29289Z' fill='%232C2D2E'/%3E%3C/svg%3E");
      display: block;
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
    }
  }
}
