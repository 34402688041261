.b-giszhkh-status {
  margin: 12px 0;
  border-radius: var(--base-card-border-radius);
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    & > div {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  &--positive {
    background-color: var(--badge-positive-pale);
  }
  &--positive &__text > div {
    color: var(--badge-positive);
  }

  &--negative {
    background-color: var(--badge-negative-pale);
  }
  &--negative &__text > div {
    color: var(--badge-negative);
  }
}
