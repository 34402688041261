.base {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 4px;
}

.track {
  width: 32px;
  min-width: 32px;
  height: 20px;
  position: relative;
  border-radius: 20px;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: #c2c6ce;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 6px 4px;

  & .thumb {
    width: 16px;
    min-width: 16px;
    height: 16px;
    position: relative;
    border-radius: 50%;
    transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    background-color: white;
    left: 2px;
  }
}

.input {
  width: 0 !important;
  height: 0 !important;
  position: absolute;
  visibility: hidden;
  opacity: 0;

  &:checked + .base > .track {
    background-color: var(--main-primary);

    & > .thumb {
      transform: translate3d(12px, 0, 0);
    }
  }

  &[disabled] + .base {
    pointer-events: none;
  }
}
