@import "./colors.scss";
@import "./typography.scss";
@import "./variables.scss";

:root {
  --header-height: 72px;
  --aside-width: 300px;
  --base-field-height: 40px;

  // --text-primary: #2c2c2d;
  // --text-secondary: #898d93;
  // --text-placeholder: #818c99;
  // --text-subhead: #72767c;
  // --text-success: #0dc268;
  // --text-error: #f20000;
  --text-red: #fa4547;

  --main-neutral: #18212b;
  // --neutral-100: #f5f6f8;
  // --neutral-200: #e7ecf0;
  --controls-secondary-background: #eff2f8;
  --document-type-grey-grey-5: #edeef2;
  --document-type-grey-grey-10: #e6e7ed;
  --document-type-grey-grey-15: #dcdde8;

  --controls--primary-selection: #175ce0;

  --base-field-border-radius: 10px;
  --base-card-border-radius: 16px;
  --big-card-border-radius: 20px;

  // --background-border: rgba(43, 57, 73, 0.16);

  --stripe-transparent: rgba(0, 17, 51, 0.24);
}

* {
  margin: 0;
}

a {
  text-decoration: none;
}

body.content-overflow main {
  overflow: hidden;
}

.color--primary {
  color: var(--main-primary);
}
.color--text-primary {
  color: var(--text-primary);
}
.color--text-secondary {
  color: var(--text-secondary);
}
.color--text-placeholder {
  color: var(--text-placeholder);
}
.color--text-subhead {
  color: var(--text-subhead);
}
.color--text-success {
  color: var(--text-success);
}
.color--text-error {
  color: var(--text-error);
}
.color--red {
  color: var(--text-red);
}
.color--text-white {
  color: var(--text-white);
}
.color--text-black {
  color: var(--text-black);
}
.color--text-warning {
  color: var(--text-warning);
}

.flex {
  display: flex;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

.middle {
  align-items: center;
}

.center {
  justify-content: center;
  text-align: center;
}

.between {
  justify-content: space-between;
}

.striped-rows > *:nth-of-type(even) {
  background: var(--neutral-100);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.striped-rows > *:hover {
  background: rgba(224, 240, 255, 0.6);
}

.color--primary--btn-hover {
  color: var(--main-primary);
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    color: #0c51d8;
  }
}

.color--text-error--btn-hover {
  color: $text-error;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    color: #ca0000;
  }
}

// scrollbar
@mixin scrollbar($size, $thumbColor, $background: transparent) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    position: relative;
  }

  &::-webkit-scrollbar-thumb {
    width: $size;
    background: $thumbColor;
    border: 3px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
    margin-bottom: 12px;
  }

  &::-webkit-scrollbar-track {
    width: $size;
    background: #fff;
    border-bottom-right-radius: 10px;
  }
}

.custom-vertical-scrollbar {
  &::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    position: relative;
  }
  &::-webkit-scrollbar-track {
    width: 14px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 14px;
    background: #d6d9de;
    border: 3px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
  }
}

// alert transition
.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.infinite-scroll-component {
  overflow: initial !important;
}
