.b-service-modal {
  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 16px 0;
  }
}
