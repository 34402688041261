.p-company {
  &__loader {
    padding: 36px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    display: grid;
    grid-template-columns: 238px 300px 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 16px;
    padding: 12px 0;

    & > div:first-child {
      grid-area: 1 / 1 / 2 / 2;
    }
    & > div:nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
    }
    & > div:nth-child(3) {
      grid-area: 1 / 3 / 2 / 4;
    }
    & > div:nth-child(4) {
      grid-area: 2 / 1 / 3 / 4;
    }
  }

  &__card {
    border-radius: 32px;

    & > div {
      padding: 24px;
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      height: 100%;

      & > p {
        font-size: 40px;

        & > span {
          line-height: 1;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent !important;
          word-break: break-word;
        }
      }
    }
  }

  &__user-exist {
    background-color: #181819;

    &_neutral {
      background-color: rgba(255, 255, 255, 0.8);
    }
    &_positive {
      background-image: linear-gradient(90deg, #ade580 0%, #5abe0d 100%);
    }
    &_negative {
      background-image: linear-gradient(90deg, #ff6d6d 0%, #df2424 100%);
    }
  }

  &__users-count {
    background: linear-gradient(105.2deg, #6aa517 0%, #0512a3 100%);

    & > div > p > span {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  &__houses > div:first-child > p > span {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent !important;
    background-image: linear-gradient(90deg, #2e64f7 0%, #d8e6ff 100%);
  }

  &__splitted-card {
    border-radius: 32px;
    background: linear-gradient(90deg, #263445 0%, #894b1c 100%);
    display: flex;
    justify-content: space-between;

    &--imaged {
      background-size: cover;
      background-position: center;
      position: relative;
      overflow: hidden;

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
      }
    }

    & > div {
      min-height: 148px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      padding: 24px;
      position: relative;
      z-index: 2;

      & > p {
        font-size: 40px;
        color: rgba(255, 255, 255, 0.8);
        line-height: 1;
      }
    }

    &__small {
      border-radius: 28px;
      background: rgba(0, 0, 0, 0.5);
      margin: 12px;
      backdrop-filter: blur(8px);
    }
  }
}
